import { useEffect, useState } from 'react';
import { ButtonType } from 'src/@types/button';
import { MaxWidthType } from 'src/@types/dimensions';
import { TopbarLogo } from 'src/components/core/atoms/Logo';
import {
  ANIMATION_DURATION,
  SCROLLBAR_WIDTH_PX,
  TOPBAR_HEIGHT_PX,
  Z_INDEX
} from 'src/config';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import { useDimensions } from 'src/hooks/useDimensions';
import TopbarButtons from 'src/layouts/Topbar/TopbarButtons';
import breakpoints from 'src/theme/breakpoints';
import { getPalette } from 'src/theme/palette';

// ----------------------------------------------------------------------

export default function DefaultDesktopTopbar({
  mainButtons,
  actionButtons,
  maxWidth
}: {
  includeSearch?: boolean;
  mainButtons?: {
    component: ButtonType;
    dropdown: ButtonType[];
  }[];
  actionButtons?: ButtonType[];
  maxWidth?: MaxWidthType;
}) {
  // const {
  //   authState: { isAuthenticated }
  // } = useAuthPersistStore((store: AuthStoreType) => ({
  //   authState: store.authState
  // }));
  // const router = useRouter();
  const [dropdownIdx, setDropdownIdx] = useState({
    in: null,
    out: null
  });
  const { themeMode }: SettingsStoreType = useSettingsStore();
  const palette = getPalette({ themeMode });
  const { width } = useDimensions();

  const minWidth = Math.max(
    maxWidth ? (width - breakpoints.values[maxWidth]) / 2 : 0,
    0
  );

  useEffect(() => {
    if (dropdownIdx.out !== null) {
      setTimeout(
        () => setDropdownIdx({ in: null, out: null }),
        ANIMATION_DURATION
      );
    }
  }, [dropdownIdx]);

  return (
    <div
      className="flex size-full flex-nowrap items-center justify-between"
      style={{
        minHeight: TOPBAR_HEIGHT_PX,
        maxHeight: TOPBAR_HEIGHT_PX,
        backgroundColor: palette.background.paper,
        marginRight: SCROLLBAR_WIDTH_PX
        // pl: tailwindTheme.spacing["md"],
        // borderBottom: 1,
        // borderBottomColor: 'border.main',
      }}
    >
      <div>
        {/* Left inc. Logo */}
        <TopbarLogo />
      </div>
      {/* Center */}
      <div
        className="flex w-full grow flex-nowrap items-center pl-md"
        style={{
          minHeight: TOPBAR_HEIGHT_PX,
          maxHeight: TOPBAR_HEIGHT_PX
        }}
      >
        <div
          className="flex items-center"
          style={{
            zIndex: Z_INDEX.TOPBAR + 1,
            minHeight: TOPBAR_HEIGHT_PX,
            maxHeight: TOPBAR_HEIGHT_PX
          }}
        >
          <TopbarButtons
            buttons={mainButtons}
            style={{ transform: 'translateX(-1px)' }}
            isLeft={true}
          />
        </div>
        <div
          className="flex w-full grow items-center justify-end"
          style={{
            zIndex: Z_INDEX.TOPBAR + 1,
            minHeight: TOPBAR_HEIGHT_PX,
            maxHeight: TOPBAR_HEIGHT_PX
          }}
        >
          <TopbarButtons
            buttons={actionButtons.map((actionButton) => {
              return {
                component: actionButton,
                dropdown: []
              };
            })}
            style={{ transform: 'translateX(1px)' }}
            isLeft={false}
          />
        </div>
      </div>

      {/* Right */}
      <div className="h-full" style={{ minWidth: minWidth }} />
    </div>
  );
}
