import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  CreateReportingPeriodInput,
  CreateReportingPeriodPayload
} from 'src/__apolloGenerated__/graphql';
import { DatePicker } from 'src/components/core/atoms/DatePicker';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';

export default function CreateReportingPeriodDialog({
  open,
  setOpen,
  activeEntityId = null,
  refetchData,
  onCancel = null
}: {
  open: boolean;
  setOpen: (newState) => void;
  activeEntityId: string;
  refetchData: () => void;
  onCancel?: () => void;
}) {
  const {
    setSelectedRangeType,
    setSelectedStartDate,
    setSelectedEndDate
  }: MeasureStoreType = useMeasureStore();

  const {
    setActiveReportingPeriodId,
    setActiveReportingPeriodStartDate,
    setActiveReportingPeriodEndDate
  }: SettingsStoreType = useSettingsStore();

  const thisYear = dayjs().year();

  const firstDayOfThisYear = dayjs()
    .date(1)
    .year(thisYear)
    .month(0)
    .hour(0);
  const lastDayOfThisYear = dayjs()
    .date(1)
    .year(thisYear)
    .month(12)
    .hour(0);

  const firstDayOfThisYearFiscal = dayjs()
    .date(1)
    .year(thisYear - 1)
    .month(9)
    .hour(0);
  const lastDayOfThisYearFiscal = dayjs()
    .date(30)
    .month(8)
    .year(thisYear)
    .hour(0);

  const [variables, setVariables] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null
  });

  const { mutate: createReportingPeriod } = useBackendMutation<
    { input: CreateReportingPeriodInput },
    CreateReportingPeriodPayload
  >({
    mutation: gql`
      mutation ReportingPeriodSelectorCreateReportingPeriod(
        $input: CreateReportingPeriodInput!
      ) {
        createReportingPeriod(input: $input) {
          ok
          entity {
            reportingPeriods {
              identifier
              startDate
              endDate
            }
          }
          reportingPeriod {
            identifier
            startDate
            endDate
          }
        }
      }
    `,
    callbacks: {
      onSuccess: (data) => {
        setActiveReportingPeriodId(data?.reportingPeriod?.identifier);
        setActiveReportingPeriodStartDate(
          new Date(data?.reportingPeriod?.startDate)
        );
        setActiveReportingPeriodEndDate(
          new Date(data?.reportingPeriod?.endDate)
        );
        setSelectedRangeType('Custom');
        setSelectedStartDate(
          new Date(dayjs(data?.reportingPeriod?.startDate).toDate())
        );
        setSelectedEndDate(
          new Date(dayjs(data?.reportingPeriod?.endDate).toDate())
        );
        refetchData();
      }
    },
    errorHandlers: {}
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-screen-md">
        <div className="flex flex-col flex-nowrap">
          <div className="">
            <p className="subtitle1">New Reporting Period</p>
          </div>

          <p className="max-w-paragraph text-muted">
            Add a new reporting period
          </p>
          <div className="mt-lg flex w-full items-center gap-4">
            <Button
              variant="outline"
              size="sm"
              onClick={() =>
                setVariables({
                  ...variables,
                  startDate: new Date(firstDayOfThisYear.format()),
                  endDate: new Date(lastDayOfThisYear.format())
                })
              }
            >
              Current Calendar Year
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() =>
                setVariables({
                  ...variables,
                  startDate: new Date(
                    firstDayOfThisYearFiscal.format()
                  ),
                  endDate: new Date(lastDayOfThisYearFiscal.format())
                })
              }
            >
              Current Financial Year
            </Button>
          </div>
          <div className="mb-lg mt-md flex w-full flex-col items-center md:flex-row">
            <div className="mr-sm w-1/2">
              <DatePicker
                required
                date={variables.startDate}
                minDate={new Date('2000-01-01')}
                maxDate={new Date('2050-01-01')}
                setDate={(newDate) => {
                  setVariables({
                    ...variables,
                    startDate: newDate
                  });
                }}
                label="Start Date:"
              />
            </div>
            <div className="w-1/2">
              <DatePicker
                required
                date={variables.endDate}
                minDate={new Date('2000-01-01')}
                maxDate={new Date('2050-01-01')}
                setDate={(newDate) => {
                  setVariables({
                    ...variables,
                    endDate: newDate
                  });
                }}
                label={'End Date:'}
              />
            </div>
          </div>

          <div className="mt-lg flex w-full items-center justify-between">
            <Button
              variant="outline"
              onClick={
                onCancel ? () => onCancel() : () => setOpen(false)
              }
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                createReportingPeriod({
                  variables: {
                    input: {
                      startDate: variables.startDate,
                      endDate: variables.endDate,
                      entityIdentifier: activeEntityId
                    }
                  }
                });
                setOpen(false);
              }}
            >
              Create
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
