import { cn } from '@/lib/utils';
import { useRouter } from 'next/router';
import { CSSProperties, useRef, useState } from 'react';
import { ButtonType } from 'src/@types/button';
import { Button } from 'src/components/shad-base/button';
import { ROUTES, TOPBAR_HEIGHT_PX } from 'src/config';
import typography from 'src/theme/typography';
import { getListItemKey } from 'src/utils/format';
import PopoverMenu from '../../components/core/molecules/PopoverMenu';
import {
  ChevronDownIcon,
  ChevronRightIcon
} from '@radix-ui/react-icons';

// ----------------------------------------------------------------------

export default function TopbarButtons({
  buttons = [],
  style = {}
}: // isLeft = false
{
  buttons: {
    component: ButtonType;
    dropdown: ButtonType[];
  }[];
  style?: CSSProperties;
  isLeft?: boolean;
}) {
  const router = useRouter();
  const dropdownRefs = useRef([]);
  const [dropdownIdx, setDropdownIdx] = useState({
    in: null,
    out: null
  });
  const [hoveringLabel, setHoveringLabel] = useState(
    'Carbon Accounting'
  );
  const [hoveringSections, setHoveringSections] = useState([
    { label: 'Overview', path: ROUTES.PRODUCTS.MEASUREMENT },
    {
      label: 'Reporting & Disclosure',
      path: ROUTES.PRODUCTS.REPORTING
    },
    { label: 'Marketplace', path: ROUTES.PRODUCTS.MARKETPLACE },
    { label: 'Directory', path: ROUTES.PRODUCTS.DIRECTORY }
  ]);

  return (
    <div
      className="flex size-full flex-nowrap items-center justify-end"
      style={{
        minHeight: TOPBAR_HEIGHT_PX,
        maxHeight: TOPBAR_HEIGHT_PX,
        ...style
      }}
    >
      {buttons.map((buttonInfo, index) => {
        return (
          <div key={getListItemKey(index)}>
            <div
              className={cn(
                'relative',
                buttonInfo.component.name === 'Resources' &&
                  'hidden lg:flex'
              )}
              ref={(el) => (dropdownRefs.current[index] = el)}
              onClick={() => {
                if (buttonInfo.dropdown.length > 0) {
                  if (dropdownIdx.in === index) {
                    setDropdownIdx({
                      in: null,
                      out: dropdownIdx.in
                    });
                  } else {
                    setDropdownIdx({
                      in: index,
                      out: dropdownIdx.in
                    });
                  }
                }
              }}
              style={{
                minHeight: TOPBAR_HEIGHT_PX,
                maxHeight: TOPBAR_HEIGHT_PX
              }}
            >
              {buttonInfo.component
                ?.getAbsolutelyPositionComponent && (
                <>
                  {buttonInfo.component?.getAbsolutelyPositionComponent()}
                </>
              )}
              {buttonInfo?.dropdown[0]?.sections?.length > 0 ? (
                <PopoverMenu
                  trigger={
                    <Button
                      onClick={() => {
                        if (buttonInfo.dropdown.length == 0) {
                          router.push(buttonInfo.component.path);
                        }
                      }}
                      className="m-sm bg-background text-black hover:bg-card"
                    >
                      <div className="flex w-full flex-row flex-nowrap items-center justify-center">
                        <p
                          className="flex-nowrap text-nowrap"
                          style={{ ...typography.body2 }}
                        >
                          {buttonInfo.component.name}
                        </p>
                        {buttonInfo.dropdown.length > 0 ? (
                          <div className="ml-sm text-lg">
                            <ChevronDownIcon />
                          </div>
                        ) : null}
                      </div>
                    </Button>
                  }
                  content={
                    buttonInfo.dropdown.length > 0 ? (
                      <div className="grid w-[700px] grid-cols-2">
                        <div className="mr-sm flex flex-col">
                          {buttonInfo.dropdown.map(
                            (
                              dropdownButton: ButtonType,
                              dropdownButtonIndex
                            ) => (
                              <div
                                className={cn(
                                  'cursor-pointer rounded-xl p-sm hover:bg-card',
                                  hoveringLabel ===
                                    dropdownButton.name && 'bg-card',
                                  dropdownButton.name ===
                                    'Carbon Removal Platform' &&
                                    'cursor-default hover:bg-transparent'
                                )}
                                onMouseEnter={() => {
                                  if (
                                    dropdownButton.name !==
                                    'Carbon Removal Platform'
                                  ) {
                                    setHoveringSections(
                                      dropdownButton.sections
                                    );
                                    setHoveringLabel(
                                      dropdownButton.name
                                    );
                                  }
                                }}
                                onClick={() => {
                                  if (
                                    dropdownButton.name !==
                                    'Carbon Removal Platform'
                                  ) {
                                    router.push(dropdownButton.path);
                                    setDropdownIdx({
                                      in: null,
                                      out: dropdownIdx.in
                                    });
                                  }
                                }}
                                key={dropdownButtonIndex}
                              >
                                <div
                                  className={cn(
                                    'flex w-full flex-row flex-nowrap items-center justify-between p-sm'
                                  )}
                                >
                                  <p>{dropdownButton.name}</p>
                                  {dropdownButton.name ===
                                  'Carbon Removal Platform' ? (
                                    <div className="p-sm">
                                      <p className="body2 text-primary">
                                        Soon
                                      </p>
                                    </div>
                                  ) : (
                                    <ChevronRightIcon />
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="flex w-full flex-col">
                          <p className="p-sm text-sm text-gray-500">
                            {hoveringLabel}
                          </p>
                          <div>
                            {hoveringSections.map(
                              (section, sectionId) => {
                                return (
                                  <div
                                    className="cursor-pointer rounded-xl p-sm px-md hover:bg-card"
                                    onClick={() => {
                                      router.push(section.path);
                                    }}
                                    key={getListItemKey(sectionId)}
                                  >
                                    {section.label}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        <div className="mr-sm flex flex-col">
                          {buttonInfo.component.name}
                        </div>
                      </div>
                    )
                  }
                />
              ) : buttonInfo?.dropdown[0]?.titleOnly ? (
                <PopoverMenu
                  trigger={
                    <Button
                      onClick={() => {
                        if (buttonInfo.dropdown.length == 0) {
                          router.push(buttonInfo.component.path);
                        }
                      }}
                      className="m-sm bg-background text-black hover:bg-card"
                    >
                      <div className="flex w-full flex-row flex-nowrap items-center justify-center">
                        <p
                          className="flex-nowrap text-nowrap"
                          style={{ ...typography.body2 }}
                        >
                          {buttonInfo.component.name}
                        </p>
                        {buttonInfo.dropdown.length > 0 ? (
                          <div className="ml-sm text-lg">
                            <ChevronDownIcon />
                          </div>
                        ) : null}
                      </div>
                    </Button>
                  }
                  content={
                    <div className="flex w-full flex-col">
                      {buttonInfo.dropdown.map(
                        (dropdownButton: ButtonType, idx: number) => {
                          return (
                            <div
                              className={cn(
                                'cursor-pointer rounded-xl p-sm hover:w-full hover:bg-card',
                                hoveringLabel ===
                                  dropdownButton.name && 'bg-card',
                                dropdownButton.name ===
                                  'Carbon Removal Platform' &&
                                  'cursor-default hover:bg-transparent'
                              )}
                              onClick={() => {
                                router.push(dropdownButton.path);
                              }}
                              key={getListItemKey(idx)}
                            >
                              <p>{dropdownButton.name}</p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  }
                />
              ) : (
                <Button
                  onClick={() => {
                    if (buttonInfo.dropdown.length == 0) {
                      router.push(buttonInfo.component.path);
                    }
                  }}
                  className="m-sm bg-background text-black hover:bg-card"
                >
                  <div className="flex w-full flex-row flex-nowrap items-center justify-center">
                    <p
                      className="flex-nowrap text-nowrap"
                      style={{ ...typography.body2 }}
                    >
                      {buttonInfo.component.name}
                    </p>
                    {buttonInfo.dropdown.length > 0 ? (
                      <div className="ml-sm text-lg">
                        <ChevronDownIcon />
                      </div>
                    ) : null}
                  </div>
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
